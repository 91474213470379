body {
    margin: 0;
    padding: 0;
    background-color: #f0f0f0;
    font-family: 'Arial', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.container {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
}

.logo {
    margin-bottom: 20px;
}

.button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 15px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
}

.button:hover {
    background-color: #0056b3;
}

@media screen and (max-width: 768px) {
    .button {
        font-size: 15px;
    }
}